
import Component, {mixins} from 'vue-class-component'
import Zoom from '@/components/Zoom.vue'
import Layers from '@/components/Layers.vue'
import Search from "@/components/Search.vue";
import ObjectPanel from "@/components/ObjectPanel.vue"
import * as L from 'leaflet';
import 'leaflet-plugins/layer/tile/Yandex.js';
import 'leaflet-measure/dist/leaflet-measure.ru'
import 'leaflet/dist/leaflet.css'
import 'leaflet-measure/dist/leaflet-measure.css'
import 'leaflet.vectorgrid'
import '@/plugins/leaflet/rosreestr'
import '@/plugins/leaflet/PkkRegions'
import axios from "axios";
import {DadataSuggestionResponse, DadataSuggestion, LayerList, PkkFeature} from "@/types";
import Mixins from "@/mixins";

@Component({
  components: {
    Zoom,
    Layers,
    Search,
    ObjectPanel
  },
})
export default class Map extends mixins(Mixins) {

  // refs
  $refs!: {
    objectPanel: ObjectPanel,
    search: Search
  }

  // data
  map: L.Map | null = null
  layers: LayerList[] | null = null
  baseLayer: LayerList | null = null
  selectedObjectLayer: L.ImageOverlay | null = null
  pkkLayer: L.PkkRegions | null = null
  marker: L.Marker | null = null

  initMapCoords: L.LatLngTuple = [55.7522, 37.6156]
  initMapZoom = 6
  initQuery: string | null = null
  // initQuery: string | null = '27c5bc66-61bf-4a17-b0cd-ca0eb64192d6'
  // initQuery: string | null = '50:15:40302:6121'
  // initQuery: string | null = 'c1a7de58-072d-4340-8bc5-573fa7482803'

  created(): void {

    this.setInitQuery()

    // установка базовых координат для объекта ФИАС
    this.setInitCoordsByFiasCode()
        .then((objectIsHouse) => {

          // инициализация карты
          this.initMap()
              .then(() => {

                if (objectIsHouse) {
                  if (this.map) {

                    let latLng = this.map.getCenter()

                    this.setObjectOverlayByCoords(latLng)
                        .then(objectByCoords => {
                          this.searchObjectByCadnum(objectByCoords.attrs.id, objectByCoords.type)
                              .then(response => {
                                this.setMarker(latLng)
                                this.$refs.objectPanel.setObject(response)
                              })
                        })
                  }
                }

                // установка объекта по кадастровому номеру
                setTimeout(() => {
                  if (this.initQuery && this.stringIsCadnum(this.initQuery)) {
                    this.searchSelectCadnum(this.initQuery)
                  }
                }, 1500)

              })
        })

  }

  // определение базового запроса
  setInitQuery(): void {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    if (params.get('query')) {
      this.initQuery = params.get('query')
    }
  }

  // определение базовах координат по коду ФИАС
  setInitCoordsByFiasCode(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.initQuery && this.stringIsFias(this.initQuery)) {
        this.searchCoordsByFiasCode(this.initQuery)
            .then(suggestion => {

              if (!suggestion.data.geo_lat || !suggestion.data.geo_lon) {
                return resolve(false)
              }

              // координаты
              let lat: number = parseFloat(suggestion.data.geo_lat)
              let lng: number = parseFloat(suggestion.data.geo_lon)
              this.initMapCoords = [lat, lng]

              // зум
              switch (parseInt(suggestion.data.fias_level)) {
                case 1: // регион
                  this.initMapZoom = 8;
                  break;
                case 3: // район
                case 4: // город
                  this.initMapZoom = 12;
                  break;
                case 5: // район города
                case 6: // населенный пункт
                case 7: // улица
                case 8: // дом
                  this.initMapZoom = 18;
                  break;
                default:
                  this.initMapZoom = 16;
                  break;
              }

              if (suggestion.data.fias_level == '8') {
                // если дом, то вернем true
                return resolve(true)
              } else {
                resolve(false)
              }

            })
            .catch(() => {
              return resolve(false)
            })
      } else {
        return resolve(false)
      }

    })
  }

  searchCoordsForRegionsByOsm(regionName: string): Promise<L.LatLngTuple> {

    return new Promise((resolve, reject) => {

      axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          state: regionName,
          format: 'jsonv2'
        }
      })
          .then(response => response.data)
          .then(places => {

            if (places[0]) {

              let lat: number = places[0].lat
              let lng: number = places[0].lon

              resolve([lat, lng])
            } else {
              reject()
            }
          })
          .catch(() => {
            reject()
          })

    })

  }

  // поиск координат по коду ФИАС
  searchCoordsByFiasCode(query: string): Promise<DadataSuggestion> {

    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/address'
    const token = 'ace0f9445a4e997c57efb5b8e88baacb2b5681bf'

    return new Promise((resolve, reject) => {
      axios.post<DadataSuggestionResponse>(url, {
        query: query
      }, {
        headers: {
          Authorization: 'Token ' + token
        }
      })
          .then(response => response.data.suggestions)
          .then(suggesions => {

            if (suggesions[0]) {

              let suggestion = suggesions[0]

              if (!suggestion.data.geo_lat || !suggestion.data.geo_lon) {
                this.searchCoordsForRegionsByOsm(suggestion.data.region)
                    .then(coords => {
                      suggestion.data.geo_lat = coords[0].toString()
                      suggestion.data.geo_lon = coords[1].toString()
                      resolve(suggestion)
                    })
                    .catch(() => {
                      reject(suggestion)
                    })
              } else {
                resolve(suggestion)
              }
            } else {
              reject()
            }
          })
          .catch(() => reject())
    })
  }

  // подготовка объекта со слоями
  initLayers(): void {
    new Promise(() => {

      this.layers = [
        {
          'key': 'yandex',
          'title': 'Карта Яндекс',
          'layer': new L.Yandex('yandex#map', {
            mapOptions: {
              yandexMapDisablePoiInteractivity: true,
              suppressMapOpenBlock: true
            }
          })
        },
        {
          'key': 'yandexSatellite',
          'title': 'Спутник Яндекс',
          'layer': new L.Yandex('yandex#satellite')
        },
        {
          'key': 'osm',
          'title': 'Open Street Map',
          'layer': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            detectRetina: true,
            zIndex: -1
          })
        },
        {
          'key': 'google',
          'title': 'Карта Google',
          'layer': L.tileLayer(
              "https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
                // retina: '@2x',
                detectRetina: true,
                zIndex: -1
              }
          )
        },
        {
          'key': 'googleSatellite',
          'title': 'Спутник Google',
          'layer': L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            zIndex: -1
          })
        }
      ]

    })
  }

  // инициализация яндекс карт
  initYmaps(): Promise<void> {
    return new Promise((resolve) => {

      const selector = document.head.querySelector('script#ymaps')

      if (selector !== null) {
        resolve()
      } else {
        const script = document.createElement('script')

        script.id = 'ymaps'
        const key = '6adfd5e4-bea1-4e37-afb4-6eaf74395628';
        script.src = `https://api-maps.yandex.ru/2.1/?apikey=${key}&lang=ru_RU`
        document.head.append(script);

        script.onload = () => {
          resolve()
        };
      }
    })
  }

  // инициализация карты
  async initMap(): Promise<void> {

    await this.initYmaps()
    await this.initLayers()

    this.map = new L.Map('map', {
      zoomControl: false
    }).setView(this.initMapCoords, this.initMapZoom)

    if (this.map) {

      this.marker = L.marker(this.initMapCoords, {
        icon: L.divIcon({
          iconSize: [40, 50],
          iconAnchor: [20, 50]
        }),
        opacity: 0
      }).addTo(this.map);

      const measureControl = new L.Control.Measure({
        position: 'bottomleft',
        primaryLengthUnit: 'meters',
        secondaryLengthUnit: 'kilometers',
        primaryAreaUnit: 'sqmeters',
        secondaryAreaUnit: undefined
      });
      measureControl.addTo(this.map);

      this.map.on('click', (event: L.LeafletMouseEvent) => {
        this.onClickMap(event)
      })

      this.pkkLayer = new L.PkkRegions().addTo(this.map);
      new L.Rosreestr().addTo(this.map)
      this.setLayer('yandex')
    }

    return Promise.resolve()

  }

  // установка слоя
  setLayer(name: string): void {

    if (!this.layers) return

    let newLayer = this.layers.find((x: LayerList) => x.key === name);

    if (this.map && newLayer) {
      if (this.baseLayer && this.baseLayer.layer) {
        this.map.removeLayer(this.baseLayer.layer)
      }

      this.baseLayer = newLayer
      if (this.baseLayer) {
        this.baseLayer.layer.addTo(this.map)
      }
    }
  }

  // зум+
  mapZoomIn(): void {
    if (this.map)
      this.map.zoomIn();
  }

  // зум-
  mapZoomOut(): void {
    if (this.map)
      this.map.zoomOut();
  }

  // установка маркера по координатам
  setMarker(latLng: L.LatLngLiteral): void {

    if (!this.marker) return

    this.marker.setLatLng([latLng.lat, latLng.lng]);
    this.marker.setOpacity(1);

  }

  // обработчик клика по карте
  onClickMap(event: L.LeafletMouseEvent): void {

    if (!this.map) return;

    let lat: number = event.latlng.lat
    let lng: number = event.latlng.lng

    if (this.map.getZoom() < 15) return

    this.setMarker({lat, lng})
    this.setObjectOverlayByCoords({lat, lng})
        .then(objectByCoords => {
          this.searchObjectByCadnum(objectByCoords.attrs.id, objectByCoords.type)
              .then(response => {
                this.$refs.objectPanel.setObject(response)
              })
        })

  }

  // поиск объекта по координатам
  searchObjectByCoords(lat: number, lng: number): Promise<PkkFeature> {
    return new Promise<PkkFeature>(resolve => {

      interface Params {
        params: {
          sq: {
            type: string,
            coordinates: [number, number]
          },
          tolerance: number,
          limit: number
        }
      }

      let params: Params = {
        params: {
          sq: {
            "type": "Point",
            "coordinates": [lng, lat]
          },
          tolerance: 1,
          limit: 11
        }
      }

      let types = [1, 5]

      types.forEach((type) => {
        axios.get("https://pkk.rosreestr.ru/api/features/" + type, params)
            .then(response => {
              if (response.data.features[0]) {
                resolve(response.data.features[0])
              }
            })
      })
    })
  }

  // поиск объекта по кадастровому номеру
  searchObjectByCadnum(cadnum: string, type?: number): Promise<PkkFeature> {
    return new Promise(resolve => {
      cadnum = this.cadnumNormalizer(cadnum)
      let types = [1, 5]

      if (type) {
        types = [type]
      }

      types.forEach((type) => {
        axios.get(`https://pkk.rosreestr.ru/api/features/${type}/${cadnum}`)
            .then(response => {
              if (response.data.feature) {
                resolve(response.data.feature)
              }
            })
      })
    })
  }

  // поиск слоя с подствекой объекта
  findSelectedObjectLayer(feature: PkkFeature, layer: number): void {

    setTimeout(() => {

      if (!this.map) return;

      if (this.selectedObjectLayer) {
        this.selectedObjectLayer.remove()
      }

      // Поправить
      let WIDTH: number = this.map.getSize().x;
      let HEIGHT: number = this.map.getSize().y;

      // Какая-то хрень
      if (!this.map.options.crs) return
      let r: L.Bounds | undefined = this.map.getPixelBounds();

      let a: L.LatLng | undefined = this.map.unproject(r.getTopRight())
      let s: L.Point | undefined = this.map.options.crs.project(a);

      let b: L.LatLng | undefined = this.map.unproject(r.getBottomLeft())
      let l: L.Point | undefined = this.map.options.crs.project(b);

      // Слой
      let layers: string
      let layerDefs: string

      if (layer == 1) {
        layers = 'show:7,8'
        layerDefs = '{"8":"id = \'' + feature.attrs.id + '\'", "8":"id = \'' + feature.attrs.id + '\'"}'
      } else {
        layers = 'show:3,4,5'
        layerDefs = '{"3":"id = \'' + feature.attrs.id + '\'", "4":"id = \'' + feature.attrs.id + '\'", "5":"id = \'' + feature.attrs.id + '\'"}'
      }

      let bbox: string = [l.x, l.y, s.x, s.y].join(",")
      let size: string = WIDTH + ',' + HEIGHT

      let query: Array<string> = [
        'f=image',
        'format=png32',
        'dpi=96',
        'transparent=true',
        'imageSR=102100',
        'bboxSR=102100',
        `layers=${encodeURIComponent(layers)}`,
        `size=${encodeURIComponent(size)}`,
        `bbox=${encodeURIComponent(bbox)}`,
        `layerDefs=${encodeURIComponent(layerDefs)}`
      ]

      this.selectedObjectLayer = new L.ImageOverlay(
          'https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreSelected/MapServer/export?' + query.join('&'),
          this.map.getBounds(),
          {
            opacity: 0.5
          }
      );

      this.map.addLayer(this.selectedObjectLayer);
    }, 500)
  }

  // установка слоя с подстветкой объекта по координатам
  setObjectOverlayByCoords(latLng: L.LatLngLiteral): Promise<PkkFeature> {
    return new Promise(resolve => {
      this.searchObjectByCoords(latLng.lat, latLng.lng)
          .then(objectByCoords => {
            this.findSelectedObjectLayer(objectByCoords, objectByCoords.type)
            resolve(objectByCoords)
          })
    })
  }

  // позиционирование карты на основе объекта из pkk
  mapPositionByPkkFeature(feature: PkkFeature, setMarker = false): void {

    if (!this.map) return
    if (!feature.extent) return;

    // Позиционирование
    let pointL = new L.Point(feature.extent.xmax, feature.extent.ymax);
    let pointR = new L.Point(feature.extent.xmin, feature.extent.ymin);
    let latlngL = L.CRS.EPSG3857.unproject(pointL);
    let latlngR = L.CRS.EPSG3857.unproject(pointR);
    let bounds = L.latLngBounds(latlngL, latlngR);

    if (bounds) {
      this.map.fitBounds(bounds);
    }

    if (setMarker) {
      let center = new L.Point(feature.center.x, feature.center.y);
      let markerLatLng = L.CRS.EPSG3857.unproject(center);
      this.setMarker(markerLatLng)
    }

  }

  // обработчик модуля поиска - поиск объекта по адресу
  searchSelectAddress(lat: number, lng: number): void {
    this.searchObjectByCoords(lat, lng)
        .then(objectByCoords => {
          this.searchSelectCadnum(objectByCoords.attrs.id)
        })

  }

  // обработчик модуля поиска - поиск объекта по кадастровому номеру
  searchSelectCadnum(cadnum: string): void {
    this.searchObjectByCadnum(cadnum)
        .then(objectByCadnum => {
          // уставновка данных объекта
          this.$refs.objectPanel.setObject(objectByCadnum)
          // поиск слоя подстветки объекта
          this.findSelectedObjectLayer(objectByCadnum, objectByCadnum.type)
          // позиционирование
          this.mapPositionByPkkFeature(objectByCadnum, true)
        })
  }

}
